<div class="logo">
  <a href="https://www.creative-tim.com?ref=bda-sidebar-logo" class="simple-text logo-mini">
    <div class="logo-img">
      <img src="http://www.valmitran.com/assets/imgs/logo-2.png" />
    </div>
  </a>
  <a href="https://www.creative-tim.com?ref=bda-sidebar-logo" class="simple-text logo-normal">
    VALMITRAN S.A
  </a>
</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuItem of menuItems"
      class="{{ menuItem.class }} nav-item"
    >
      <a [routerLink]="[menuItem.path]">
        <i class="tim-icons  {{ menuItem.icon }}"></i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
  </ul>
</div>
