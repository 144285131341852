<footer class=" footer">
  <div class=" container-fluid">
    <div class=" copyright">
      &copy; {{ test | date: "yyyy" }}
      <i class=" tim-icons icon-heart-2"> </i> by
      <a href="#" target="_blank"> Valmitran </a>
      and Uco + Educaboot.
    </div>
  </div>
</footer>
